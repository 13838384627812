import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { SignDoNotEnter } from 'react-bootstrap-icons';

function RoadClosuresComponent({ roadClosures, disableHr }) {
    return (
        <div>
            {!disableHr && <hr />}
            <Row>
                <Col xs={12} className="text-white bg-dark">
                    <b><SignDoNotEnter /> Temporary Road Closure Details <SignDoNotEnter /></b>
                </Col>
                {roadClosures.map(closure => (
                    <Col xs={12}>
                        <Row key={closure.description}>
                            <Col xs={12}>
                                <h5>{closure.description}</h5>
                            </Col>
                            <Col xs={12}>
                                <Row className="justify-content-center">
                                	<Col className="d-none d-sm-block" sm={2} md={4}>&nbsp;</Col>
                                    <Col xs={12} sm={6} md={2} className="text-center">
                                        {closure.from && <span>Closed: <b>{closure.from}</b></span>}
                                    </Col>
                                    <Col xs={12} sm={6} md={2} className="text-center">
                                        {closure.to && <span>To: <b>{closure.to}</b></span>}
                                    </Col>
                                    <Col className="d-none d-sm-block" sm={2} md={4}>&nbsp;</Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                ))}
                <Col xs={12}>
                    <i>Road closure information as provided by our integration with the DfT. Note: These may not always be caused by flooding.</i>
                </Col>
            </Row>
        </div>
    );
}

export default RoadClosuresComponent;
